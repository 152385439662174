<template>
    <v-app>
        <router-view />
    </v-app>
</template>

<script setup lang="ts">
    import { useAuth } from '@/composables/useAuth';

    useAuth().checkAuth();
</script>
