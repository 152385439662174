<template>
   <div>
    <v-app-bar app color="primary" dark>
      <v-toolbar-title class="tw-text-center tw-font-bold">Unlock JMC</v-toolbar-title>
    </v-app-bar>
  </div>
  <div class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-h-full align-center">
    <v-form v-if="!alert" ref="form" @submit.prevent="submit" class="tw-w-80">
      <v-card  class="mx-auto tw-max-w-sm tw-w-full" variant="flat">
        <div class="tw-px-4">
        <div class="tw-text-center tw-mb-4 tw-mt-2">
        <v-text-field
          v-model="firstValue"
          label="Primeiro Valor"
          class="form-input"
          :rules="firstValueRules"
          type="number"
        ></v-text-field>
      </div>

<div class="tw-text-center tw-mb-4">
        <v-text-field
          v-model="secondValue"
          label="Segundo Valor"
          class="form-input"
          :rules="secondValueRules"
          type="number"
        ></v-text-field>
      </div>

      <div class="tw-text-center tw-mb-4">
        <v-btn type="submit" color="primary" block class="form-btn">Submeter</v-btn>
      </div>
</div>

</v-card>
</v-form>
  
    <div v-if="alert" class="tw-mt-4 tw-h-auto">
      <h1 class="tw-font-medium tw-text-center tw-mb-4 tw-text-2xl ">PIN</h1>
      <h2 class="tw-font-semibold tw-text-center tw-text-5xl tw-tracking-widest">{{pinResult}}</h2>
      <v-btn type="submit" @click="resetForm" color="primary" variant="outlined" block class="form-btn">Reset</v-btn>
    </div>
  </div>
</template>

<script setup lang="ts">
    import { ref } from 'vue';

    const firstValue = ref('');
    const secondValue = ref('');

    const firstValueRules = [
       // make sure it is 4 numbers a dot and 2 numbers
        (v: string) => /^\d{4}\.\d{2}$/.test(v) || 'O primeiro valor deve ser no formato 0000.00',
    ];
    
    const secondValueRules = [
        // make sure it is from 4 to 5 numbers
        (v: string) => /^\d{4,5}$/.test(v) || 'O segundo valor deve ser de 4 a 5 números',
    ];

    const form = ref();
    const alert = ref(false);
    const pinResult = ref('');

    async function submit() {
      if(!form.value) return;

      const { valid } = await form.value.validate();
      if (!valid) return;

      // get the first 2 numbers from the firstValue
      const firstValueFirst2Numbers = parseInt(firstValue.value.substring(0, 2));

      // get the second 2 numbers from the firstValue  ignore the dot and get the last 2 numbers from the firstValue  join the firstValueSecond2Numbers and the firstValueLast2Numbers to get a 4 numbers string and convert it to a number
      const firstValueSecondAndLast2Numbers = parseInt(firstValue.value.substring(2, 4) + firstValue.value.substring(5, 7));

      // get the secondValue and convert it to a number
      const secondValueNumber = parseInt(secondValue.value);

      // do the calculations
      const result = (firstValueSecondAndLast2Numbers + secondValueNumber) * firstValueFirst2Numbers;

      pinResult.value = '' + result;

      alert.value = true;
    }

    function resetForm() {
      alert.value = false;
      firstValue.value = '';
      secondValue.value = '';
      pinResult.value = '';
    }

</script>

<style scoped></style>