<template>
    <v-bottom-navigation bg-color="primary">
        <v-btn @click="unlock" value="unlock">
            <v-icon>mdi-lock-open</v-icon>

            <span>Unlock</span>
        </v-btn>

        <v-btn v-if=showUnlockJMC @click="unlockJMC" value="unlock_jmc">
            <v-icon>mdi-wrench-cog</v-icon>

            <span>Unlock JMC</span>
        </v-btn>

        <v-btn value="logout" @click="logout">
            <v-icon>mdi-logout</v-icon>

            <span>Logout</span>
        </v-btn>
    </v-bottom-navigation>
</template>

<script setup lang="ts">
    import { useAuthStore } from '@/store/auth';
    import { useRouter } from 'vue-router';
    import { computed } from 'vue';

    const authStore = useAuthStore();
    const $router = useRouter();
    
    // if the user.role.name === JMC Technician then show the v-btn unlock_jmc
    const showUnlockJMC = computed(() => authStore.getUser?.role?.name === 'JMC Technician');

    async function logout() {
        authStore.$reset();
        $router.push({ name: 'Login' });
    }

    async function unlockJMC() {
        $router.push({ name: 'UnlockJMC' });
    }

    async function unlock() {
        $router.push({ name: 'Unlock' });
    }
</script>

<style scoped></style>
