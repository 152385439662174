import slugify from 'slugify';
import _ from 'lodash';
import { useI18n } from 'vue-i18n';

export function getErrorKey(error: unknown, localKey: string = ''): string {
    if (!localKey.endsWith('.')) localKey += '.';
    const slug = slugify(_.get(error, 'response.data.error.message', 'unknown_error'), {
        replacement: '_',
        trim: true,
        lower: true,
    });

    return slug == 'unknown_error' ? slug : `${localKey}${slug}`;
}

export function getErrorMessage(error: unknown, localKey: string = ''): string {
    const { t } = useI18n();
    return t(getErrorKey(error, localKey), t('shared.unknown_error'));
}
