<template>
  <div>
    <v-app-bar app color="primary" dark>
      <v-toolbar-title class="tw-text-center tw-font-bold">Unlock</v-toolbar-title>
    </v-app-bar>
  </div>
  <div class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-h-full align-center">

    <v-form v-if="!alert" ref="form" @submit.prevent="submit" class="tw-w-80">
      <v-card  class="mx-auto tw-max-w-sm tw-w-full" variant="flat">
      <div class="tw-px-4">
        <div class="tw-text-center tw-mb-4 tw-mt-2">
          <v-select
            variant="outlined"
            v-if=showUnlockJMC
            label="Empresa"
            :items="companies"
            item-title="name"
            item-value="pin"
            v-model="company"
            :rules="selectRules"
          ></v-select>
        </div>

        <div class="tw-text-center tw-mb-4">
        <v-text-field
          v-model="firstValue"
          class="form-input"
          label="Primeiro Valor"
          :rules="firstValueRules"
          type="number"
        ></v-text-field>
        </div>

        <div class="tw-text-center tw-mb-4">
        <v-text-field
          v-model="secondValue"
          class="form-input"
          label="Segundo Valor"
          :rules="secondValueRules"
          :error-messages="customError"
          type="number"
        ></v-text-field>
      </div>

        <div class="tw-text-center tw-mb-4">
        <v-btn type="submit" color="primary" block class="form-btn">Submeter</v-btn>
      </div>
    </div>

      </v-card>
    </v-form>

    <div v-if="alert" class="tw-mt-4 tw-h-auto">
      <h1 class="tw-font-medium tw-text-center tw-mb-4 tw-text-2xl ">PIN</h1>
      <h2 class="tw-font-semibold tw-text-center tw-text-5xl tw-tracking-widest">{{pinResult}}</h2>
      <v-btn type="submit" @click="resetForm" color="primary" variant="outlined" block class="form-btn">Reset</v-btn>
    </div>
  </div>
</template>

<script setup lang="ts">
    import { ref } from 'vue';
    import { useAuthStore } from '@/store/auth';
    import { computed } from 'vue';
    import axios from '@axios';

    const authStore = useAuthStore();
    
    // if the user.role.name === JMC Technician then show the v-btn unlock_jmc
    const showUnlockJMC = computed(() => authStore.getUser?.role?.name === 'JMC Technician');

    const companies = ref<object[]>([])

    if(showUnlockJMC.value){
      getData();
    }

    const firstValue = ref('');
    const secondValue = ref('');
    const company = ref('');
    const customError = ref('');

    const form = ref();
    const alert = ref(false);
    const pinResult = ref('');

    const selectRules = [
      (v: string) => !!v || 'Selecione uma empresa',
    ];

    const firstValueRules = [
       // make sure it is 4 numbers
       (v: string) => /^\d{4}$/.test(v) || 'O primeiro valor deve ser no formato 0000',
    ];
    const secondValueRules = [
        // make sure it is from 4 to 5 numbers
        (v: string) => /^\d{4,5}$/.test(v) || 'O segundo valor deve ser de 4 a 5 números',
    ];

    async function getData() {
      // get the companies from the api using axios
      let response = await axios.get('/companies');
      // set the companies to the response data
      for (let i = 0; i < response.data.data.length; i++) {
        companies.value.push({name: response.data.data[i].name, pin: response.data.data[i].pin});
      }
    }
    
    async function submit() {
        if(!form.value) return;

        const { valid } = await form.value.validate();
        if (!valid) return;

        // get the first value and convert it to a number
        const firstValueNumber = parseInt(firstValue.value);

        // get the second value and convert it to a number
        const secondValueNumber = parseInt(secondValue.value);

        if(showUnlockJMC.value){
          // get the id of the company from the select input
          const companyPin = parseInt(company.value);

          // do the calculations
          const result = (firstValueNumber + secondValueNumber) * companyPin;
          pinResult.value = '' + result;

        } else {
          if(!authStore.getUser?.company?.pin){
            // display an error message saying that the user should try logging out and logging in again if the error persists contact the administrator
            customError.value = 'Por favor, tente sair e entrar novamente. Se o erro persistir, entre em contacto com o administrador';

            return;
          }
          // do the calculations
          const result = (firstValueNumber + secondValueNumber) * parseInt(authStore.getUser?.company?.pin);
          pinResult.value = '' + result;
        }
        alert.value = true;
    }

    async function resetForm() {
        alert.value = false;
        firstValue.value = '';
        secondValue.value = '';
        pinResult.value = '';

        if(showUnlockJMC.value){
          company.value = '';
        }
    }

</script>

<style scoped></style>